<template>
    <div>
        <div class="align"><button class="close-btn" @click="handleBack()">X</button></div>
        <div class="help" >
        <h2>Keyboard shortcuts</h2>
        <table class="my-tab" width="100%" align="center">
        <thead>
            <tr>
            <th>Standard actions</th>
            <th>Operator</th>
            <th>Rename</th>
            </tr>    
        </thead>
        <tbody>
            <tr>
            <td>Undo: ctrl + z</td>
            <td>Connect: ⬆ + c</td>
            <td>Double click</td>          
            </tr>
            <tr>
            <td>Redo: ctrl + y</td>
            <td>Predicate: ⬆ + p</td>
            <td></td>        
            <td></td>   
            </tr>
            <tr>
            <td>Save: ctrl + s</td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>Export png: ctrl + p</td>
            <th>Branch</th>
            <td></td>
            <td></td>  
            </tr>
            <tr>
            <td>New QT project: ctrl + ⬆ + n</td>
            <td>Notes: ⬆ + n</td>
            <td></td>
            <td></td>  
            </tr>
            <tr>
            <td>Open QT project: ctrl + o</td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
        </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import { mainStore } from '../store/main';

export default {
  name: 'Help',
  methods : {
    handleBack(){
      mainStore.closeModal();
    }
  }
};
</script>

<style scoped>
.align{
  text-align: right;
  margin-bottom: 0px;
  margin-top: 7px;
}
.close-btn{
  background-color: transparent !important;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 16px 0px 0px;
}
.my-tab{
    position: relative !important;
    text-align: left !important;
    float: left !important;
    background: inherit !important;
    table-layout: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
    margin-bottom: 40px!important;
}
th{
    font-weight: bold;
    font-size: 18px;
}
td{
   width: auto;
     }
h2 {
  margin-bottom: 1em;
  margin-top: 0px;
}
svg {
  width: 16px;
  vertical-align: bottom;
}
.help{
  position: relative;
  text-align: center;
  padding: 0px 40px 40px 40px;
}
.subheader {
  margin: 0.5em 0 1.5em 0;
  font-size: 0.9em;
}
.version {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 0.8em;
  color: #888;
}
@media all and (max-width: 720px) {
  .help {
    font-size: 0.85em;
    padding: 10px 20px 40px 20px;
  }
}
</style>