<template>
  <DiagramEditor contextName="schemaDesign" :store="store">
    <SchemaDesignEditorToolbox />
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
  </DiagramEditor>
</template>

<script>
import DiagramEditor from './DiagramEditor';
import ErrorStatusBar from './ErrorStatusBar';
import SchemaDesignEditorToolbox from './SchemaDesignEditorToolbox';

import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';

export default {
  name: 'SchemaDesignEditor',
  components: {
    DiagramEditor,
    ErrorStatusBar,
    SchemaDesignEditorToolbox
  },
  setup() {
    const store = mainStore.schemaDesign;
    
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();

    getContext('schemaDesign').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectItem: id => store.selectItem(id),
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem()
    });

    return {
      store,
      selectedItemWarnings,
      selectedItemErrors
    };
  }
};
</script>