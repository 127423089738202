<template>
  <div class="button-container" v-bind:class="{ disabled }">
    <div class="button" v-bind:class="{ disabled, selected }" @click="$emit('click')">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 80 80" v-html="svg"></svg>
      <p>{{ text }}</p>
    </div>
    <span class="tooltip" v-if="disabled && errorTooltip">{{ errorTooltip }}</span>
  </div>
</template>

<script>
import { computed } from 'vue';

import icons from '../icons';

export default {
  name: 'ToolboxButton',
  props: ['text', 'icon', 'errorTooltip', 'disabled', 'selected'],
  emits: ['click'],
  setup(props) {
    const svg = computed(() => icons[props.icon]);

    return {
      svg
    };
  }
};
</script>

<style scoped>
svg {
  width: 100%;
  height: 33px;
  fill: #191716;
}
p {
  margin: 0;
  font-size: 11px;
  line-height: 10px;
  text-align: center;
  user-select: none;
}
.button-container {
  position: relative;
}
.button-container.disabled {
  cursor: not-allowed;
}
.button-container:hover .tooltip {
  display: block;
}
.button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 46px;
  width: 46px;
  padding: 4px;
  border-radius: 4px;
}
.button.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}
.button.selected {
  background: #e6af2e;
}
.button:not(.disabled):not(.selected):hover {
  background: #f2dea6;
}
.tooltip {
  display: none;
  position: absolute;
  width: 200px;
  top: 10px;
  left: 75px;
  padding: 6px 10px;
  background: #f0f0f0;
  font-size: 12px;
}
@media all and (max-width: 540px) {
  svg {
    height: 29px;
  }
  p {
    font-size: 10px;
  }
  .button {
    width: 40px;
    height: 40px;
    padding: 2px;
    border-radius: 4px;
  }
}
</style>
<style>
.button svg text {
  cursor: pointer;
}
</style>