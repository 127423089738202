import { createApp } from 'vue';
import gsap from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import App from './App.vue';

import './registerServiceWorker';

gsap.registerPlugin(CSSPlugin);

const app = createApp(App);
app.use(Toast);
app.mount('#app');