<template>
  <div class="splash-container">
    <div class="bg"></div>
    <div class="logo">
      <svg class="a" version="1.0" xmlns="http://www.w3.org/2000/svg" width="180px" height="180px" viewBox="0 0 466.000000 375.000000"  preserveAspectRatio="xMidYMid meet">
      <g transform="translate(10.000000,480.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <!-- pi greco --><path d="M3315 3309 c-211 -10 -597 -37 -710 -49 -49 -6 -117 -13 -150 -15 -79 -7 -317 -41 -382 -55 -58 -13 -86 -37 -86 -77 0 -46 43 -73 116 -73 34 0 78 4 97 9 33 9 137 22 336 41 50 5 102 12 117 15 17 5 27 3 27 -4 0 -21 -94 -201 -156 -297 -265 -417 -410 -693 -421 -799 -7 -73 68 -145 135 -130 28 6 135 91 282 224 47 42 103 92 125 111 22 19 123 116 225 216 224 218 324 312 318 299 -2 -5 -17 -37 -32 -70 -28 -60 -44 -106 -117 -350 -59 -194 -59 -190 -59 -331 0 -184 20 -234 113 -277 39 -19 76 -27 152 -32 150 -9 262 20 340 90 17 15 43 35 58 45 16 10 50 38 75 62 26 24 94 82 152 128 58 46 148 125 200 175 52 50 133 123 180 161 91 76 142 129 77 80 -41 -30 -251 -144 -432 -234 -60 -30 -159 -88 -220 -127 -160 -105 -313 -176 -379 -175 -63 1 -90 80 -84 237 4 89 10 124 37 198 17 50 41 125 52 168 11 43 31 104 45 135 49 111 104 292 104 343 0 42 -5 55 -29 80 -51 51 -102 34 -220 -75 -150 -138 -326 -303 -430 -402 -105 -99 -183 -170 -230 -209 -27 -22 -23 -14 138 278 44 81 81 150 81 153 0 23 144 296 163 308 15 10 61 17 137 21 63 3 122 8 130 10 21 7 306 33 500 46 143 10 168 14 188 32 40 37 25 97 -29 116 -36 12 -282 12 -534 0z"/>
      </g></svg>
      <svg class="b" version="1.0" xmlns="http://www.w3.org/2000/svg" width="180px" height="180px" viewBox="0 0 466.000000 375.000000"  preserveAspectRatio="xMidYMid meet">
      <g transform="translate(400.000000,250.000000) rotate(-120 0 0) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M1420 3109 c-81 -14 -207 -55 -277 -92 -94 -49 -295 -250 -380 -381 -114 -176 -133 -259 -88 -393 55 -168 180 -251 449 -298 109 -19 176 -19 243 -2 35 9 50 10 46 3 -4 -6 -74 -116 -155 -244 -188 -295 -221 -351 -323 -547 -141 -271 -192 -438 -182 -599 8 -142 50 -216 133 -232 48 -9 78 14 92 68 5 24 22 108 37 188 16 84 45 191 68 255 48 130 575 1182 622 1240 17 22 48 66 68 97 20 31 65 93 100 138 113 142 151 249 145 404 -3 81 -7 99 -41 167 -27 57 -51 89 -91 123 -59 50 -165 97 -231 103 -126 10 -186 11 -235 2z m150 -238 c83 -32 155 -81 192 -131 32 -44 33 -48 33 -140 0 -83 -4 -102 -28 -155 -51 -108 -170 -246 -247 -285 -61 -32 -96 -38 -235 -46 l-120 -6 -89 34 c-101 40 -147 78 -191 158 -42 78 -42 152 1 235 51 101 89 153 151 208 60 52 187 136 227 149 12 4 73 6 136 4 86 -3 129 -9 170 -25z"/>
      </g></svg>
    </div>
    <div class="progress" v-if="progress > 12">
      <div class="bar">
        <div class="bar-inner" v-bind:style="{ width: progress + '%' }"></div>
      </div>
    </div>
    <div class="polito">
       
    </div>
    
  </div>
       
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Splash',
  setup() {
    const progress = ref(0);

    setInterval(() => {
      let increment = 0;
      if(Math.random() < 0.7)
        increment = parseInt(Math.random() * 16);
      progress.value = Math.min(95, progress.value + increment);
    }, 100);

    return {
      progress
    };
  }
};
</script>

<style scoped>
.splash-container {
  height: 100%;
  width: 100;
}
.bg {
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.1;
}
.logo, .progress, .polito {
  position: absolute;
  width: 100%;
  text-align: center;
}
.logo {
  top: calc(40% - 60px);
}
.logo svg {
  position: absolute;
  left: calc(50% - 54px);
}
.logo .a {
  opacity: 0;
  animation: 1200ms cubic-bezier(0.34, 1.56, 0.64, 1) 80ms forwards logo-a;
}
.logo .b {
  opacity: 0;
  animation: 1200ms cubic-bezier(0.34, 1.56, 0.64, 1) 340ms forwards logo-b;
}
.logo .c {
  opacity: 0;
  animation: 380ms cubic-bezier(0.34, 1.56, 0.64, 1) 500ms forwards logo-c;
}
.progress {
  top: calc(40% + 140px);
}
.bar {
  width: 120px;
  height: 2px;
  margin: auto;
  background: #f2dea6;
}
.bar-inner {
  height: 2px;
  background: #e6af2e;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.polito {
  bottom: 10%;
  opacity: 0.6;
}
.polito img {
  width: 180px;
  filter: invert(0.3);
}
</style>
<style>
@keyframes logo-a {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(-20px) translateY(5px);
  }
}
@keyframes logo-b {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotate(+120deg) translateX(90px) translateY(25px);
  }
}
@keyframes logo-c {
  0% {
    opacity: 0.5;
    transform: rotate(40deg);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotate(0);
  }
}
</style>