<template>
  <div class="layout-container">
    <!--<div class="code flex flex-col" v-bind:class="{ minimized: showModelToggleOn }" @click="codeClicked">
      <div class="header code-h"><PanelTitle>{{ codeHeaderTitle }}</PanelTitle></div>
      <div class="content"><slot name="code"></slot></div>
    </div>-->
    <div class="model flex flex-col" v-bind:class="{ minimized: !showModelToggleOn }" @click="modelClicked">
      <div class="header model-h"></div>
      <div class="content"><slot name="model"></slot></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'CodeModelLayout',
  props: ['codeHeaderTitle', 'modelHeaderTitle'],
  components: {
  },
  setup() {
    const showModelToggleOn = ref(true);

    function codeClicked() {
      showModelToggleOn.value = false;
    }
    function modelClicked() {
      showModelToggleOn.value = true;
    }

    return {
      showModelToggleOn,
      codeClicked,
      modelClicked
    };
  }
};
</script>

<style scoped>
.layout-container {
  display: flex;
  height: 100%;
}
.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.code {
  width: 26%;
  padding: 10px 0 10px 20px;
  z-index: 1;
  border-right: 1px solid #bbb;
}
.model {
  width: 100%;
  position: relative;
  padding: 10px 20px;
}
.code-h, .model-h {
  color: #aaa;
  position: relative;
  left: 26px;
  top: -5px;

}
@media all and (max-width: 720px) {
  .code:not(.minimized), .model:not(.minimized) {
    width: calc(100% - 24px);
  }
  .minimized {
    width: 24px;
    padding: 50% 0;
    background: #007acc;
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
  .minimized  {
    width: 24px;
    padding: 50% 0;
    background: #007acc;
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
  .minimized .header {
    margin: 0;
  }
  .minimized .content {
    display: none;
  }
}
</style>
<style>
@media all and (max-width: 720px) {
  .minimized .editor {
    display: none;
  }
  .minimized .header p {
    margin: 0 0.3em;
    color: #fff;
  }
}
</style>