<template>
  <div class="container">
    <div>
      <button class="buttons" @click="zoom(false)">-</button>
      <button class="buttons" @click="zoom(true)">+</button>
    </div>
  </div>
</template>
  
<script>
import { getContext } from '../lib/editor';
export default {
  name: 'Zoom',
  setup() {
    function zoom(in_flag) {
      var f=1/0.9;
      if (in_flag==false){
        f=0.9;
      }
      const context = getContext('erDesign');
      const modelScene = context.diagram.scene.$model, overlaysScene = context.diagram.scene.$overlays;
      const oldScale = modelScene.scale;
      const newScale = oldScale * f;
      modelScene.scale = overlaysScene.scale = newScale;
      context.transform.scale= modelScene.scale;
      modelScene.translation.sub(
      (350 - modelScene.translation.x) * (f - 1),
      (350 - modelScene.translation.y) * (f - 1)
    );
    [context.transform.scale, context.transform.offsetX, context.transform.offsetY] = [modelScene.scale, modelScene.translation.x, modelScene.translation.y];
    context.two.update();
    }
    
    return {
      zoom
    };
  }
};

</script>
<style>

.buttons{
  background-color:whitesmoke;
  height: 40px;
  width: 38px;
  font-size: 2.7em;
  line-height: 50%;
  text-align: center;
  padding: 0px 3px 6px 5px;
  margin:3px;
  

}
.container{ 
  position: absolute;
  right:40px;
  bottom:40px;
}

</style>