import { computed, ref } from 'vue';
import rfdc from 'rfdc';

import { Store } from './store';
import ERDesignStore from './erDesign';
import ERRestructuringStore from './erRestructuring';
import ERTranslationStore from './erTranslation';
import SchemaDesignStore from './schemaDesign';
import config from '../config';
import { generateUid } from '../lib/utils/core';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';

const clone = rfdc();
const storage = window.localStorage;

class MainStore extends Store {
  constructor() {
    super();
    this.saveTimeout = null;
    this.history = ref([]);
    this.historyPointer = ref(0);

    this.erDesign = new ERDesignStore(this);
    this.erRestructuring = new ERRestructuringStore(this);
    this.erTranslation = new ERTranslationStore(this);
    this.schemaDesign = new SchemaDesignStore(this);
    this.editorData = null;
    this.showNotesDataMenu = false;
    this._initLocalStorage();
    this._initServices();
    this._initHistory();
    this._initDesign();
  }
  __replace(state) {
    this.state.uid = state.uid || 1;
    this.state.step = state.step || 'erDesign';
    this.state.restructuringHappened = state.restructuringHappened || false;
    this.state.draftSaved = null;
    this.state.showMenu = false;
    this.state.showModal = false;
    this.state.containerWidth = 500,
    this.state.containerHeight = 580;
  }
  data() {
    return {
      step: 'erDesign',
      restructuringHappened: false,
      draftSaved: null,
      showMenu: false,
      showModal: false,
      containerWidth: 500,
      containerHeight: 580,
    };
  }
  _initLocalStorage() {
    // user identifier
    const fgpt = storage.getItem('fgpt');
    if(!fgpt)
      storage.setItem('fgpt', generateUid());
    // analytics service
    const as = storage.getItem('as');
    if(as == null)
      storage.setItem('as', 1); // todo: ask for permission
  }
  _initServices() {
    if(config.analytics.enabled && storage.getItem('as'))
      analytics.init(storage.getItem('fgpt'));
  }
  _initHistory() {
    this.history.value.splice(0);
    this.historyPointer.value = 0;
    this.history.value.push(this.serializeState());
  }
  _initDesign() {
    const model = storage.getItem('model');
    if(model) {
      try {
        this._deserializeState(model, false);
        analytics.event(analyticsEvents.APP_RESTORE_LOCAL_DESIGN, { step: this.state.step });
      } catch(err) {
        storage.removeItem('model');
      }
    }
  }
  _deserializeState(raw, save) {
    const state = JSON.parse(raw);
    this.erDesign.__replace(state.erDesign);
    this.erRestructuring.__replace(state.erRestructuring);
    this.erTranslation.__replace(state.erTranslation);
    this.schemaDesign.__replace(state.schemaDesign);
    this.editorData=state.editorData;
    this.showNotesDataMenu = state.showNotesDataMenu;
    this.__replace(state._main);
    if(save)
      this._saveLocally();
  }
  _saveLocally() {
    this.state.draftSaved = true;
    storage.setItem('model', this.serializeState());
  }
  _stepTo(sub) {
    this.state.step = sub;
    this._initHistory();
    this._saveLocally();
  }
  $empty() {
    return computed(() => this.erDesign.isEmpty());
  }
  $step() {
    return computed(() => this.state.step);
  }
  $draftSaved() {
    return computed(() => this.state.draftSaved);
  }
  $showMenu() {
    return computed(() => this.state.showMenu);
  }
  $showModal() {
    return computed(() => this.state.showModal);
  }
  $canUndo() {
    return computed(() => this.canUndo());
  }
  $canRedo() {
    return computed(() => this.canRedo());
  }
  $showNotesDataMenu() {
    return computed(() => this.state.showNotesDataMenu);
  }
  $containerWidth() {
    return computed(() => this.state.containerWidth);
  }
  $containerHeight() {
    return computed(() => this.state.containerHeight);
  }
  $editorData() {
    return computed(() => this.state.editorData);
  }
  canUndo() {
    return this.historyPointer.value > 0;
  }
  canRedo() {
    return this.history.value.length > (this.historyPointer.value + 1);
  }
  undo() {
    if(!this.canUndo())
      return;
    this.historyPointer.value--;
    this._deserializeState(this.history.value[this.historyPointer.value], true);
    analytics.event(analyticsEvents.UNDO);
  }
  redo() {
    if(!this.canRedo())
      return;
    this.historyPointer.value++;
    this._deserializeState(this.history.value[this.historyPointer.value], true);
    analytics.event(analyticsEvents.REDO);
  }
  pushState() {
    this.state.draftSaved = false;
    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(this._saveLocally.bind(this), config.core.savePeriodSeconds * 1000);

    this.history.value.splice(this.historyPointer.value + 1);
    this.history.value.push(this.serializeState());
    if(this.history.value.length > config.core.historySize)
      this.history.value.shift();
    this.historyPointer.value = this.history.value.length - 1;
  }
  resetState() {
    this.erDesign.__replace({});
    this.erRestructuring.__replace({});
    this.erTranslation.__replace({});
    this.schemaDesign.__replace({});
    this.editorData!=null ? this.editorData=null : '';
    this.showNotesDataMenu = false;
    this.__replace({});
    this._initHistory();
    this._saveLocally();
  }
  serializeState() {
    let editorToReturn = this.editorData;
    if(this.editorData && this.editorData.html == ''){
      editorToReturn = null;
    }
    return JSON.stringify({
      _main: this.state,
      erDesign: this.erDesign.state,
      erRestructuring: this.erRestructuring.state,
      erTranslation: this.erTranslation.state,
      schemaDesign: this.schemaDesign.state,
      editorData:  editorToReturn,
      showNotesDataMenu: this.showNotesDataMenu,
      containerWidth: this.containerWidth,
      containerHeight: this.containerHeight,
    });
  }
  loadFile(data) {
    this._deserializeState(data, true);
    this._initHistory();
    analytics.event(analyticsEvents.APP_LOAD_DESIGN);
  }
  resetEditorMenus() {
    this.erDesign.toggleMenu();
    this.erRestructuring.toggleMenu();
    this.erTranslation.toggleMenu();
    this.schemaDesign.toggleMenu();
  }
  toggleMenu() {
    this.state.showMenu = !this.state.showMenu;
  }
  toggleNotesDataMenu() {
    if(this.editorData && this.editorData.html == '')
      this.editorData=null;
    this.state.showNotesDataMenu = !this.state.showNotesDataMenu;
  }
  openModal(modal) {
    this.state.showModal = modal;
  }
  closeModal() {
    this.state.showModal = false;
  }
  backToDesign() {
    analytics.event(analyticsEvents.STEP_ER_DESIGN, { fromStep: this.state.step });
    this._stepTo('erDesign');
  }
  moveToRestructuring() {
    if(this.state.step == 'erDesign' && this.erDesign.state.edited) {
      this.erRestructuring.__replace(clone(this.erDesign.state));
      this.erDesign.state.edited = false;
    }
    analytics.event(analyticsEvents.STEP_RESTRUCTURING, { fromStep: this.state.step });
    this._stepTo('erRestructuring');
  }
  moveToTranslation() {
    if(this.state.step == 'erDesign' && this.erDesign.state.edited) {
      this.state.restructuringHappened = false;
      this.erTranslation.__replace(clone(this.erDesign.state));
      this.erDesign.state.edited = false;
    } else if(this.state.step == 'erRestructuring' && this.erRestructuring.state.edited) {
      this.state.restructuringHappened = true;
      this.erTranslation.__replace(clone(this.erRestructuring.state));
      this.erRestructuring.state.edited = false;
    }
    analytics.event(analyticsEvents.STEP_TRANSLATION, { fromStep: this.state.step });
    this._stepTo('erTranslation');
  }
  moveToSchemaDesign() {
    if(this.state.step == 'erTranslation' && this.erTranslation.state.edited) {
      this.schemaDesign.__replace(clone(this.erTranslation.state));
      this.erTranslation.state.edited = false;
    }
    analytics.event(analyticsEvents.STEP_SCHEMA_EDITING, { fromStep: this.state.step });
    this._stepTo('schemaDesign');
  }
  resetRestructuring() {
    this.erRestructuring.__replace(clone(this.erDesign.state));
    this.erRestructuring.state.edited = true;
    this._saveLocally();
    analytics.event(analyticsEvents.RESET_RESTRUCTURING);
  }
  resetTranslation() {
    const state = this.state.restructuringHappened ? this.erRestructuring.state : this.erDesign.state;
    this.erTranslation.__replace(clone(state));
    this.erTranslation.state.edited = true;
    this._saveLocally();
    analytics.event(analyticsEvents.RESET_TRANSLATION);
  }
  setEditorData(e){
    if(e!=null)
      e.html ? this.editorData=e.html :this.editorData=e ;
    else
      this.editorData="";
    this.erDesign._itemsUpdated();
  }
  getEditorData(){
    return this.editorData;
  }
  setContainerWidth(width){
    this.state.containerWidth = width;
  }
  setContainerHeight(height){
    this.state.containerHeight = height;
  }
  getContainerWidth(){
    return this.state.containerWidth;
  }
  getContainerHeight(){
    return this.state.containerHeight;
  }
  setShowNotesDataMenu(showNotesDataMenu){
    this.state.showNotesDataMenu = showNotesDataMenu;
  }
}

export const mainStore = new MainStore();